<template>
  <div class="deposit">
    <div class="title backgroundWhite relative">
      <h1>残保金审核</h1>
      <div class="downloads">
        <a href="~/static/lanzhou/gsjytjb.pdf" download="就业统计表.pdf"  class="bs_text_oo"><el-button type="primary" size="mini">下载统计报表模板</el-button></a>
        <el-button type="primary" size="mini" v-if="isEd" @click="$router.push('/enterprise/centre/lanzhou/deposit/edit')">新增申请</el-button>
      </div>
    </div>
    <div class="content marT10 backgroundWhite">
      <div class="offline_box text_C" v-if="tableData.length >= 0">
        <el-table
            class="text_C table"
            :data="tableData"
            stripe
            style="width: 100%">
          <el-table-column
              prop="checkYear"
              align="center"
              label="审核年度">
          </el-table-column>
          <el-table-column
              prop="creatdate"
              align="center"
              label="提交时间">
          </el-table-column>
          <el-table-column
              align="center"
              label="审核状态">
            <template slot-scope="scope">
              {{scope.row.auditstatus=='3'?'未提交':(scope.row.auditstatus=='2'?'审核不通过':(scope.row.auditstatus=='1'?'审核通过':"待审核"))}}
            </template>
          </el-table-column>
          <el-table-column
              prop="DC"
              align="center"
              label="残疾人职工数">
          </el-table-column>
          <el-table-column
              align="center"
              class="relative"
              label="回执单下载">
            <template slot-scope="scope">
              <span class="color66" v-if="!scope.row.officialReceiptState">暂无</span>
              <span class="color66" v-if="scope.row.officialReceiptState =='1'">
                <a v-if="scope.row.officialReceiptBootom" :href="'/upload'+scope.row.officialReceiptBootom.substr(scope.row.officialReceiptBootom.lastIndexOf('/'),scope.row.officialReceiptBootom.length)" target="_blank"
                >打开
              </a>

              </span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="操作">
            <template slot-scope="scope">
              <!--<button class="b_btn color66 relative"-->
              <!--disabled-->
              <!--@click="$router.push('/center/blindEdit',{query:scope.row.id})"-->
              <!--v-if="scope.row.status === '未通过' || scope.row.status === '未提交'">编辑-->
              <!--<i class="line"></i>-->
              <!--</button>-->
              <button class="b_btn bs_text_oo relative"
                      v-if="isEdit(scope.row.checkYear,scope.row.auditstatus)"
                      @click="$router.push('/enterprise/centre/lanzhou/deposit/edit',{query:scope.row.id})">
                编辑
                <i class="line"></i>
              </button>
              <button class="b_btn bs_text_oo"
                      @click="goRouter('/enterprise/centre/lanzhou/deposit/detail',scope.row.id)">详情
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        class="work_pagination"
        :current-page.sync="form.pageNum"
        :page-size="form.pageSize"
        layout="prev, pager, next, jumper"
        :prev-text="pagination.pre"
        :next-text="pagination.next"
        :total="pagination.total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      tableData: [
      ],

      isEd:true,
      pagination: {
        total: 0,

        pre: "上一页",
        next: "下一页"
      },
      form:{
        pageNum: 1,
        pageSize: 10,
      }

    }
  },
  methods: {
    //从新页面打开
    goRouter(url,id) {
      const {href} = this.$router.resolve({
        path: url,
        query: {id: id}
      })
      window.open(href)
    },
    downloadOfficial:function (url) {

    },
    isEdit:function (year,status) {
      const date = new Date().getFullYear()-1;
      console.log("status111==="+status);

      if(date==year){
        this.isEd=false;
      }

      if(date==year&&(status=='3'||status=='2')){
        return true;
      }else{
        return false;
      }
    },
    handleCurrentChange(val){
      this.form.pageNum = val;
      this.queryCheck();
    },
    queryCheck:function () {
      let formData=this.form;
      this.$api.lanzhouGetALLCheckApi(this.form).then(repsonse=>{
        repsonse=repsonse.data;
        if(repsonse.success){
          this.tableData=repsonse.data.list;
          this.pagination.total=repsonse.data.total;

        }else{

        }

      })
    }

  },
  created:function () {
    this.queryCheck();
  }
}
</script>

<style scoped lang="less">
@import "~assets/lanzhou/enterprise.less";
@import "~assets/lanzhou/deposit.less";
</style>
